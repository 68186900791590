.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  width: 150px;
  height: 150px;
  border-top: 3.5px solid #2876b4;
  border-right: 3.8px solid #2876b4;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: rotation 1.4s linear infinite;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 18px;
  font-family: "Helvetica";
  color: #2876b4;
  border: none;
  border-radius: none;
  animation: unset;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}